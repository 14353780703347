import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { playSound, answerQuestion, nextQuestion } from '../actions';
import { Fade } from 'react-reveal';
import CorrectSplash from './CorrectSplash';
import Spinner from './Spinner';
import { RoundButton } from './_styled_components';
import Answers from './Answers';
import { ContentArea, ContentLower } from './Content';

const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  height: 300px;
  width: 400px;
`;

const SoundPlaying = styled.div`
  width: 330px;
  flex-grow: 1;
`;

const FullHeight = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

// const ReplayContainer = styled.div`
//   width: 330px;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   margin: 0px 0px 40px 0px;
// `;

export class Question extends PureComponent {
  constructor(props) {
    super(props);
    this.playQuestionSample = this.playQuestionSample.bind(this);
    this.answerQuestion = this.answerQuestion.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
  }

  componentDidMount() {
    if (this.props.question.audio){
      this.playQuestionSample();
    }
  }

  playQuestionSample() {
    const { dispatch, question } = this.props;
    const { audio } = question;
    dispatch(playSound(audio));
  }

  answerQuestion(event) {
    const { answerCorrect } = event.target.dataset;
    const { dispatch } = this.props;
    dispatch(answerQuestion(answerCorrect));
  }

  nextQuestion() {
    const { dispatch } = this.props;
    dispatch(nextQuestion({}));
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    // const { question: { audio }} = this.props;

    if (this.props.question.audio !== prevProps.question.audio){
      this.playQuestionSample();
    }
  }

  render() {
    const { question, game } = this.props;
    const { text, answers } = question;
    const { playbackInProgress, answerStatus } = game;
    return (
      <ContentColumn>
        <h2>{text}</h2>
        {answerStatus === 'pending' && playbackInProgress && (
          <ContentArea>
            <SoundPlaying>
              <Spinner />
            </SoundPlaying>
          </ContentArea>
        )}
        {answerStatus === 'pending' && !playbackInProgress && answers && (
          <ContentArea>
            <Answers answers={answers} answerQuestion={this.answerQuestion} />
          </ContentArea>
        )}
        {answerStatus === 'pending' && !playbackInProgress && (
          <Fade>
            <ContentLower>
              <RoundButton type="button" onClick={this.playQuestionSample}>
                Replay
              </RoundButton>
            </ContentLower>
          </Fade>
        )}
        {answerStatus === 'correct' && (
          <Fade duration={3000}>
            <CorrectSplash nextQuestion={this.nextQuestion} />
          </Fade>
        )}
        {answerStatus === 'incorrect' && (
          
              <IncorrectSplash nextQuestion={this.nextQuestion} playbackInProgress={playbackInProgress} />
          
        )}
      </ContentColumn>
    );
  }
}

Question.propTypes = {
  dispatch: PropTypes.func.isRequired,
  game: PropTypes.object,
  question: PropTypes.object,
};

const IncorrectSplash = props => {
  const { nextQuestion: nextQuestionFunc, playbackInProgress } = props;
  return (
    <FullHeight>
      <ContentArea>
        <h1>Incorrect!</h1>
      </ContentArea>
      <ContentLower>
        { !playbackInProgress && <RoundButton onClick={nextQuestionFunc}>next</RoundButton> }
      </ContentLower>
    </FullHeight>
  );
};

IncorrectSplash.propTypes = {
  nextQuestion: PropTypes.func.isRequired,
};

export default connect(state => ({ game: state.game }))(Question);
