import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { home } from '../actions';
import { ShibaButton } from './_styled_components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

class LevelSummary extends PureComponent {
  constructor(props) {
    super(props);
    this.home = this.home.bind(this);
  }

  home() {
    const { dispatch } = this.props;
    dispatch(home());
  }

  render() {
    const { score, total } = this.props;
    const percentage = score === 0 ? 0 : (score / total) * 100;
    return (
      <Container>
        <h1>Score: {percentage}% </h1>
        <div>
          <ShibaButton onClick={this.home}>Done</ShibaButton>
        </div>
      </Container>
    );
  }
}

LevelSummary.propTypes = {
  dispatch: PropTypes.func.isRequired,
  score: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({ game: state.game });

export default connect(mapStateToProps)(LevelSummary);
