const levels = [
  {
    title: 'Easy',
    description: 'Major and minor chords',
    locked: false,
    questions: [
      {
        text: 'Major or Minor?',
        audio: `${process.env.PUBLIC_URL}/media/audio/C.mp3`,
        answers: [
          {
            text: 'Major',
            correct: true,
          },
          {
            text: 'Minor',
            correct: false,
          },
        ],
      },
      {
        text: 'Major or Minor?',
        audio: `${process.env.PUBLIC_URL}/media/audio/Dm.mp3`,
        answers: [
          {
            text: 'Major',
            correct: false,
          },
          {
            text: 'Minor',
            correct: true,
          },
        ],
      },
    ],
  },
  {
    title: 'Medium',
    description: 'Minor and sus4 chords',
    locked: false,
    questions: [
      {
        text: 'Minor or Sus4?',
        audio: `${process.env.PUBLIC_URL}/media/audio/C.mp3`,
        answers: [
          {
            text: 'Minor',
            correct: true,
          },
          {
            text: 'Sus4',
            correct: false,
          },
        ],
      },
      {
        text: 'Sus2 or Minor?',
        audio: `${process.env.PUBLIC_URL}/media/audio/Dm.mp3`,
        answers: [
          {
            text: 'Major',
            correct: false,
          },
          {
            text: 'Minor',
            correct: true,
          },
        ],
      },
    ],
  },
  {
    title: 'Hard',
    description: 'Coming soon...',
    locked: true,
    questions: [
      {
        text: 'Minor or Sus4?',
        audio: `${process.env.PUBLIC_URL}/media/audio/C.mp3`,
        answers: [
          {
            text: 'Minor',
            correct: true,
          },
          {
            text: 'Sus4',
            correct: false,
          },
        ],
      },
      {
        text: 'Sus2 or Minor?',
        audio: `${process.env.PUBLIC_URL}/media/audio/Dm.mp3`,
        answers: [
          {
            text: 'Major',
            correct: false,
          },
          {
            text: 'Minor',
            correct: true,
          },
        ],
      },
    ],
  },
];

export default levels;
