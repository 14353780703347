import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { startGame } from '../actions';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { ShibaButton } from './_styled_components';

const Header = styled.h1`
  font-size: 48px;
  margin-top: 80px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 300px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const StartContainer = styled.div`
  flex-grow: 2;
  justify-content: center;
`;

class StartScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.start = this.start.bind(this);
  }

  start() {
    const { dispatch } = this.props;
    dispatch(startGame());
  }

  render() {
    return (
      <MainContainer>
        <HeaderContainer>
          <Header>Chord Shiba</Header>
        </HeaderContainer>
        <StartContainer>
          <ShibaButton onClick={this.start}>Start</ShibaButton>
        </StartContainer>
      </MainContainer>
    );
  }
}

StartScreen.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return { game: state.game };
}

export default connect(mapStateToProps)(StartScreen);
