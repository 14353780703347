import { buffers, eventChannel, END } from 'redux-saga';

export const createPlaySoundChannel = (name) => {
  return eventChannel(emitter => {

    const onPlay = () => {
      emitter({ play: true });
    };

    const onEnd = () => {
      emitter({ ended: true });
      emitter(END);
    };

    window.csAudio.on('play', onPlay);
    window.csAudio.on('end', onEnd);

    window.csAudio.play(name);

    return () => {
      window.csAudio.off('play', onPlay);
      window.csAudio.off('end', onEnd);
    };
  }, buffers.sliding(2))
};
