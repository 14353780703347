import { handleActions } from 'redux-actions';
import immutable from 'immutability-helper';
import { ActionTypes } from '../constants/index';
import allChords from '../resources/chords';
import {Howl} from 'howler';

function initAudio(){
  window.csAudio = new Howl({
    src: ['/assets/media/audio/cs_audio_sprites.mp3'],
    sprite: {...getChordSprites(), ...getOtherAudio()} 
  });
}

function getChordSprites(){
  let sprites = {};
  allChords.forEach(chord => sprites[chord.name] = chord.sprite);
  return sprites;
}

function getOtherAudio(){
  return {
    'success': [84000, 1000]
  }
}

export const gameState = {
  currentView: 'home',
  level: -1,
  questions: [],
  questionNumber: 0,
  score: 0,
  playbackInProgress: false,
  answerStatus: 'pending',
  playbackUrl: ''
};

initAudio();

function getRandomChords(number, modes) {
  const availableChords = allChords.filter(chord => modes.includes(chord.mode));

  const chords = [];
  for (let i = 0; i < number; i++) {
    const randIndex = Math.floor(Math.random() * availableChords.length);
    chords.push(availableChords[randIndex]);
  }

  return chords;
}

function createQuestions(number, questionText, modes) {
  const chordSelection = getRandomChords(number, modes);
  const questions = chordSelection.map(chord => {
    const answers = modes.map(mode => {
      const text = mode.charAt(0).toUpperCase() + mode.slice(1);
      return {
        text,
        correct: mode === chord.mode,
      };
    });
    return {
      text: questionText,
      audio: chord.name,
      answers,
    };
  });
  return questions;
}

function getQuestionSet(levelIndex) {
  switch (levelIndex) {
    case 0:
      return createQuestions(10, 'Major or Minor', ['major', 'minor']);
    case 1:
      return createQuestions(10, 'Minor or Sus4', ['minor', 'sus4']);
    default:
      return createQuestions(10, 'Major or Minor', ['major', 'minor']);
  }
}

export default {
  game: handleActions(
    {
      [ActionTypes.HOME]: () => gameState,
      [ActionTypes.START_GAME]: state =>
        immutable(state, {
          currentView: { $set: 'levelSelect' },
        }),
      [ActionTypes.SELECT_LEVEL]: (state, { payload }) => {
        const questions = getQuestionSet(payload.level);
        return immutable(state, {
          level: { $set: payload.level },
          currentView: { $set: 'play' },
          questions: { $set: questions },
        });
      },
      [ActionTypes.ANSWER_QUESTION]: (state, { payload }) => {
        const { score } = state;

        let outcome = 'incorrect';
        let newScore = score;
        if (payload.answerCorrect === 'true') {
          outcome = 'correct';
          newScore = score + 1;
        }

        return immutable(state, {
          score: { $set: newScore },
          answerStatus: { $set: outcome },
        });
      },
      [ActionTypes.PLAYBACK_STARTED]: state =>
        immutable(state, {
          playbackInProgress: { $set: true },
        }),
      [ActionTypes.PLAYBACK_ENDED]: state =>
        immutable(state, {
          playbackInProgress: { $set: false },
        }),
      [ActionTypes.NEXT_QUESTION]: state =>
        immutable(state, {
          questionNumber: { $set: state.questionNumber + 1 },
          answerStatus: { $set: 'pending' },
          playbackInProgress: { $set: false }
        }),
    },
    gameState,
  ),
};
