import React, { PureComponent } from 'react';
import styled, { keyframes } from 'styled-components';

const skBounce = keyframes`
    0%, 100% { 
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    } 50% { 
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
`;

const SpinnerContainer = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 50px auto;
`;

const DoubleBounce1 = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fcddc9;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: ${skBounce} 2s infinite ease-in-out;
  animation: ${skBounce} 2s infinite ease-in-out;
`;

const DoubleBounce2 = styled(DoubleBounce1)`
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
`;

class Spinner extends PureComponent {
  render() {
    return (
      <SpinnerContainer>
        <DoubleBounce1 />
        <DoubleBounce2 />
      </SpinnerContainer>
    );
  }
}

export default Spinner;
