import styled from 'styled-components';
import fonts from '../resources/fonts';

export const ShibaButton = styled.button`
  display: block;
  background-color: transparent;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  font-family: ${fonts.secondary};
  font-size: 20px;
  color: #fcddc9;
  padding: 10px 45px;
  margin: 0px 20px 20px 0px;
  border-radius: 10px;
`;

export const RoundButton = styled.button`
  display: block;
  background-color: transparent;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  font-family: ${fonts.secondary};  
  color: #fcddc9;
  padding: 0px 20px;
  margin: 0px 10px 0px 0px;
  height: 30px;
  border-radius: 20px;
`;
