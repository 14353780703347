import React from 'react';
import { createGlobalStyle } from 'styled-components';
import colours from '../resources/colours';
import fonts from '../resources/fonts';

const GlobalStyle = createGlobalStyle`
    body{
        background-color: ${colours.backgroundPrimary};
        color: ${colours.foregroundPrimary};
        font-family: ${fonts.primary};
    }

    h1{
        font-size: 2.5em;
    }
`;

export default () => <GlobalStyle />;