import React, { PureComponent } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
`;

export class ContentArea extends PureComponent {
    render(){
        return <Container>
            { this.props.children }
        </Container>
    }
}

export class ContentLower extends PureComponent{
    render(){
        return <div>
            { this.props.children }
        </div>
    }
}