import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { PureComponent, Fragment } from 'react';
import { nextQuestion } from '../actions';
import Question from './Question';
import LevelSummary from './LevelSummary';

export class Level extends PureComponent {
  constructor(props) {
    super(props);
    this.nextQuestion = this.nextQuestion.bind(this);
  }

  nextQuestion() {
    const { dispatch } = this.props;
    dispatch(nextQuestion());
  }

  render() {
    const { game } = this.props;
    const { questions, questionNumber, score } = game;

    const question = questions[questionNumber];
    const inProgress = questions.length > questionNumber;
    return (
      <Fragment>
        {inProgress && <Question question={question} />}
        {!inProgress && <LevelSummary score={score} total={questions.length} />}
      </Fragment>
    );
  }
};

Level.propTypes = {
  dispatch: PropTypes.func.isRequired,
  game: PropTypes.shape({
    questionNumber: PropTypes.number,
  }),
};

// function mapStateToProps(state) {
//   return { game: state.game };
// }

export default connect(state => ({ game: state.game }))(Level);
