import PropTypes from 'prop-types';
import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import { Fade } from 'react-reveal';
import { ShibaButton } from './_styled_components';

const AnswerButton = styled(ShibaButton)`
  float: left;
  width: 150px;
`;

class Answers extends PureComponent {
  render() {
    const { answers } = this.props;
    return (
      <Fragment>
        {answers &&
          answers.map((answer, index) => {
            const { answerQuestion } = this.props;
            return (
              <Fade key={index}>
                <AnswerButton data-answer-correct={answer.correct} onClick={answerQuestion}>
                  {answer.text}
                </AnswerButton>
              </Fade>
            );
          })}
      </Fragment>
    );
  }
}

Answers.propTypes = {
  answerQuestion: PropTypes.func.isRequired,
  answers: PropTypes.array.isRequired,
};

export default Answers;
