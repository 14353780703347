import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { selectLevel } from '../actions';
import levels from '../resources/levels';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 400px;
  height: 300px;
`;

const hoverEffect = css`
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const LevelListItem = styled.div`
  width: 350px;
  display: flex;
  flex-direction: row;
  margin: 20px 0px 0px 0px;
`;

const LevelTitle = styled.div`
  border: 1px solid #fcddc9;
  border-radius: 10px;
  border-right: 1px solid #fcddc9;
  font-size: 24px;
  padding: 10px;
  width: 120px;
  text-align: center;
  border-radius: 10px 0px 0px 10px;
  cursor: default;
  ${props => (props.locked ? null : hoverEffect)};
`;

const LevelDescription = styled.div`
  padding: 10px;
  font-family: 'PT Sans', sans-serif;
  background-color: #fcddc9;
  color: #376888;
  width: 100%;
  border-radius: 0px 10px 10px 0px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  cursor: default;
`;

const TitleContainer = styled.div`
  width: 120px;
`;

const LevelSelectionTile = props => {
  const { click, level, locked, title, description } = props;
  return (
    <LevelListItem>
      <TitleContainer>
        <LevelTitle onClick={click} data-selected-level={level} locked={locked}>
          {title}
        </LevelTitle>
      </TitleContainer>
      <LevelDescription>
        <span>{description}</span>
      </LevelDescription>
    </LevelListItem>
  );
};

LevelSelectionTile.propTypes = {
  click: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  level: PropTypes.number.isRequired,
  locked: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export class LevelSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.selectLevel = this.selectLevel.bind(this);
  }

  selectLevel(event) {
    const { selectedLevel } = event.target.dataset;
    const { dispatch } = this.props;

    if (levels[selectedLevel].locked) {
      return;
    }
    dispatch(selectLevel(parseInt(selectedLevel, 10)));
  }

  render() {
    return (
      <MainContainer>
        {levels.map((level, index) => (
          <LevelSelectionTile
            key={index}
            click={this.selectLevel}
            level={index}
            title={level.title}
            locked={level.locked}
            description={level.description}
          />
        ))}
      </MainContainer>
    );
  }
};

LevelSelect.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return { game: state.game };
}

export default connect(mapStateToProps)(LevelSelect);
