import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import DogDisplay from './DogDisplay';
import StartScreen from './StartScreen';
import LevelSelect from './LevelSelect';
import Level from './Level';
import GlobalStyles from './GlobalStyles';

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  @media (max-width: 700px){
    
  }

`;

const LeftContentArea = styled.div`
  width: 50%;
  height: 300px;
  margin: 10px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;

  @media (max-width: 700px){
    display: none;
  }
`;

const ContentArea = styled.div`
  width: 50%;
  height: 300px;
  margin: 10px;
  @media (max-width: 700px){
    width: 100%;
  }
`;

class MainLayout extends PureComponent {
  render() {
    const { game } = this.props;
    const { currentView } = game;
    return (
      <MainContainer>
        <GlobalStyles/>
        <LeftContentArea>
          <DogDisplay />
        </LeftContentArea>
        <ContentArea>
          {currentView === 'home' && <StartScreen />}
          {currentView === 'levelSelect' && <LevelSelect />}
          {currentView === 'play' && <Level />}
        </ContentArea>
      </MainContainer>
    );
  }
};

MainLayout.propTypes = {
  game: PropTypes.object,
};

function mapStateToProps(state) {
  return { game: state.game };
}

export default connect(mapStateToProps)(MainLayout);
