/**
 * @module Sagas/ChordShiba
 * @desc ChordShiba
 */

import { all, call, put, take, takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants/index';
import { createPlaySoundChannel } from './createPlaySoundChannel';

export function* playSoundSaga({ payload }) {
  const channel = yield call(createPlaySoundChannel, payload.name);

  while (true) {
    const { play, ended } = yield take(channel);
    if (play) {
      yield put({
        type: ActionTypes.PLAYBACK_STARTED,
        payload: {},
      });
    }
    if (ended) {
      yield put({
        type: ActionTypes.PLAYBACK_ENDED,
        payload: {},
      });
      return;
    }
  }
}

export default function* root() {
  yield all([takeLatest(ActionTypes.PLAY_SOUND, playSoundSaga)]);
}
