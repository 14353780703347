import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import styled, { keyframes, css } from 'styled-components';
import { Fade } from 'react-reveal';
import shibaMedium from '../assets/media/images/shibaMedium.png';

const bounceKeyFrames = keyframes`
  0% {
    margin-top: 0px;
  }
  50% {
    margin-top: 30px;
  }
  100% {
    margin-top: 0px;
  }
`;

const MainContainer = styled.div`
  width: 330px;
  position: relative;
`;

const NotesContainer = styled.div`
  font-weight: 100;
  font-size: 32px;
  left: 200px;
  top: 20px;
  position: absolute;
`;

const AnimationStyle = css`
  animation: ${bounceKeyFrames} 2s ease-in-out 0s infinite;
  animation-delay: ${props => `${props.offset * 0.15}s`};
`;

const Note = styled.div`
  float: left;
  position: relative;
  ${props => (props.animate ? AnimationStyle : null)};
`;

const SplashImage = styled.img`
  margin-left: auto;
  margin-right: auto;
`;

const NoteCharacters = ['♩', '♫', '♩', '♪'];

class DogDisplay extends PureComponent {

  render() {
    const { game } = this.props;
    const { currentView, playbackInProgress } = game;
    const animate = playbackInProgress || currentView === 'home';
    return (
      <Fade duration={3000}>
        <MainContainer>
          <SplashImage src={shibaMedium} />
          {animate && (
            <NotesContainer>
              {NoteCharacters.map((note, index) => (
                <Note key={index} offset={index} animate={animate}>
                  {note}
                </Note>
              ))}
            </NotesContainer>
          )}
        </MainContainer>
      </Fade>
    );
  }
}

DogDisplay.propTypes = {
  game: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ game: state.game });

export default connect(mapStateToProps)(DogDisplay);
