const chords = [
  {
    name: 'A',
    mode: 'major',
    sample: 'A.mp3',
    sprite: [0, 4000] 
  },
  {
    name: 'Am',
    mode: 'minor',
    sample: 'Am.mp3',
    sprite: [4000, 4000]
  },
  {
    name: 'Asus4',
    mode: 'sus4',
    sample: 'Asus4.mp3',
    sprite: [8000, 4000]
  },
  {
    name: 'B',
    mode: 'major',
    sample: 'B.mp3',
    sprite: [12000, 4000]
  },
  {
    name: 'Bm',
    mode: 'minor',
    sample: 'Bm.mp3',
    sprite: [16000, 4000]
  },
  {
    name: 'Bsus4',
    mode: 'sus4',
    sample: 'Bsus4.mp3',
    sprite: [20000, 4000]
  },
  {
    name: 'C',
    mode: 'major',
    sample: 'C.mp3',
    sprite: [24000, 4000]
  },
  {
    name: 'Cm',
    mode: 'minor',
    sample: 'Cm.mp3',
    sprite: [28000, 4000]
  },
  {
    name: 'Csus4',
    mode: 'sus4',
    sample: 'Csus4.mp3',
    sprite: [32000, 4000]
  },
  {
    name: 'D',
    mode: 'major',
    sample: 'D.mp3',
    sprite: [36000, 4000]
  },
  {
    name: 'Dm',
    mode: 'minor',
    sample: 'Dm.mp3',
    sprite: [40000, 4000]
  },
  {
    name: 'Dsus4',
    mode: 'sus4',
    sample: 'Dsus4.mp3',
    sprite: [44000, 4000]
  },
  {
    name: 'E',
    mode: 'major',
    sample: 'E.mp3',
    sprite: [48000, 4000]
  },
  {
    name: 'Em',
    mode: 'minor',
    sample: 'Em.mp3',
    sprite: [52000, 4000]
  },
  {
    name: 'Esus4',
    mode: 'sus4',
    sample: 'Esus4.mp3',
    sprite: [56000, 4000]
  },
  {
    name: 'F',
    mode: 'major',
    sample: 'F.mp3',
    sprite: [60000, 4000]
  },
  {
    name: 'Fm',
    mode: 'minor',
    sample: 'Fm.mp3',
    sprite: [64000, 4000]
  },
  {
    name: 'Fsus4',
    mode: 'sus4',
    sample: 'Fsus4.mp3',
    sprite: [68000, 4000]
  },
  {
    name: 'G',
    mode: 'major',
    sample: 'G.mp3',
    sprite: [72000, 4000]
  },
  {
    name: 'Gm',
    mode: 'minor',
    sample: 'Gm.mp3',
    sprite: [76000, 4000]
  },
  {
    name: 'Gsus4',
    mode: 'sus4',
    sample: 'Gsus4.mp3',
    sprite: [80000, 4000]
  }
];

export default chords;
