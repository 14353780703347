import { createActions } from 'redux-actions';

import { ActionTypes } from '../constants/index';

export const {
  home,
  startGame,
  selectLevel,
  answerQuestion,
  playSound,
  playbackStarted,
  playbackEnded,
  nextQuestion,
} = createActions({
  [ActionTypes.HOME]: () => ({}),
  [ActionTypes.START_GAME]: () => ({}),
  [ActionTypes.SELECT_LEVEL]: level => ({ level }),
  [ActionTypes.ANSWER_QUESTION]: answerCorrect => ({ answerCorrect }),
  [ActionTypes.PLAY_SOUND]: name => ({ name }),
  [ActionTypes.PLAYBACK_STARTED]: () => ({}),
  [ActionTypes.PLAYBACK_ENDED]: () => ({}),
  [ActionTypes.NEXT_QUESTION]: () => ({}),
});