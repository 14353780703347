import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Fade } from 'react-reveal';
import { playSound } from '../actions';
import { RoundButton } from './_styled_components';
import { ContentArea, ContentLower } from './Content';

class CorrectSplash extends PureComponent {
  constructor(props) {
    super(props);
    this.playCorrectSound = this.playCorrectSound.bind(this);
  }

  componentDidMount() {
    this.playCorrectSound();
  }

  playCorrectSound() {
    const { dispatch } = this.props;
    dispatch(playSound('success'));
  }

  render() {
    const { nextQuestion, game } = this.props;
    const { playbackInProgress } = game;
    return (
      <div>
        <ContentArea>
          <h1>Correct!</h1>
        </ContentArea>
        <ContentLower>
          { !playbackInProgress && <Fade><RoundButton onClick={nextQuestion}>next</RoundButton></Fade> }
        </ContentLower>
      </div>
    );
  }
}

CorrectSplash.propTypes = {
  dispatch: PropTypes.func.isRequired,
  nextQuestion: PropTypes.func.isRequired,
};

export default connect(state => ({ game: state.game }))(CorrectSplash);
